/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AiAssistantFilesService } from './services/AiAssistantFilesService';
import { AiAssistantsService } from './services/AiAssistantsService';
import { AiChatCompletionsService } from './services/AiChatCompletionsService';
import { AiConversationsService } from './services/AiConversationsService';
import { AiDocumentQasService } from './services/AiDocumentQasService';
import { AiFeedbackResultsService } from './services/AiFeedbackResultsService';
import { AiFeedbacksService } from './services/AiFeedbacksService';
import { AiImageRecognitionsService } from './services/AiImageRecognitionsService';
import { AiImagesService } from './services/AiImagesService';
import { AiMessageFilesService } from './services/AiMessageFilesService';
import { AiMessagesService } from './services/AiMessagesService';
import { AiMiniToolRequestsService } from './services/AiMiniToolRequestsService';
import { AiMiniToolsService } from './services/AiMiniToolsService';
import { AiPersonasService } from './services/AiPersonasService';
import { AiRunsService } from './services/AiRunsService';
import { AiSpeechesService } from './services/AiSpeechesService';
import { AiSpeechRecognitionsService } from './services/AiSpeechRecognitionsService';
import { AppsService } from './services/AppsService';
import { AudioRecorderService } from './services/AudioRecorderService';
import { AudioRecordingsService } from './services/AudioRecordingsService';
import { BookmarksService } from './services/BookmarksService';
import { ClassroomScreenService } from './services/ClassroomScreenService';
import { CmsService } from './services/CmsService';
import { CollectionsService } from './services/CollectionsService';
import { DiscardedItemsService } from './services/DiscardedItemsService';
import { FileSharingService } from './services/FileSharingService';
import { FoldersService } from './services/FoldersService';
import { ItemsService } from './services/ItemsService';
import { LinkShortenerService } from './services/LinkShortenerService';
import { MaterialsService } from './services/MaterialsService';
import { OwnerFilesService } from './services/OwnerFilesService';
import { PinboardService } from './services/PinboardService';
import { PupilFeedbackService } from './services/PupilFeedbackService';
import { SchoolClassesService } from './services/SchoolClassesService';
import { ScreenRecorderService } from './services/ScreenRecorderService';
import { SearchService } from './services/SearchService';
import { SessionsService } from './services/SessionsService';
import { SurveysService } from './services/SurveysService';
import { TemplatesService } from './services/TemplatesService';
import { TextEditorDocumentsService } from './services/TextEditorDocumentsService';
import { UserLimitsService } from './services/UserLimitsService';
import { UsersService } from './services/UsersService';
import { VideoRecorderService } from './services/VideoRecorderService';
import { VideoRecorderFilesService } from './services/VideoRecorderFilesService';
import { VisitorsService } from './services/VisitorsService';
import { WebsiteService } from './services/WebsiteService';
import { WordCloudService } from './services/WordCloudService';
import { WorksheetsService } from './services/WorksheetsService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class FobizzToolsApi {
    public readonly aiAssistantFiles: AiAssistantFilesService;
    public readonly aiAssistants: AiAssistantsService;
    public readonly aiChatCompletions: AiChatCompletionsService;
    public readonly aiConversations: AiConversationsService;
    public readonly aiDocumentQas: AiDocumentQasService;
    public readonly aiFeedbackResults: AiFeedbackResultsService;
    public readonly aiFeedbacks: AiFeedbacksService;
    public readonly aiImageRecognitions: AiImageRecognitionsService;
    public readonly aiImages: AiImagesService;
    public readonly aiMessageFiles: AiMessageFilesService;
    public readonly aiMessages: AiMessagesService;
    public readonly aiMiniToolRequests: AiMiniToolRequestsService;
    public readonly aiMiniTools: AiMiniToolsService;
    public readonly aiPersonas: AiPersonasService;
    public readonly aiRuns: AiRunsService;
    public readonly aiSpeeches: AiSpeechesService;
    public readonly aiSpeechRecognitions: AiSpeechRecognitionsService;
    public readonly apps: AppsService;
    public readonly audioRecorder: AudioRecorderService;
    public readonly audioRecordings: AudioRecordingsService;
    public readonly bookmarks: BookmarksService;
    public readonly classroomScreen: ClassroomScreenService;
    public readonly cms: CmsService;
    public readonly collections: CollectionsService;
    public readonly discardedItems: DiscardedItemsService;
    public readonly fileSharing: FileSharingService;
    public readonly folders: FoldersService;
    public readonly items: ItemsService;
    public readonly linkShortener: LinkShortenerService;
    public readonly materials: MaterialsService;
    public readonly ownerFiles: OwnerFilesService;
    public readonly pinboard: PinboardService;
    public readonly pupilFeedback: PupilFeedbackService;
    public readonly schoolClasses: SchoolClassesService;
    public readonly screenRecorder: ScreenRecorderService;
    public readonly search: SearchService;
    public readonly sessions: SessionsService;
    public readonly surveys: SurveysService;
    public readonly templates: TemplatesService;
    public readonly textEditorDocuments: TextEditorDocumentsService;
    public readonly userLimits: UserLimitsService;
    public readonly users: UsersService;
    public readonly videoRecorder: VideoRecorderService;
    public readonly videoRecorderFiles: VideoRecorderFilesService;
    public readonly visitors: VisitorsService;
    public readonly website: WebsiteService;
    public readonly wordCloud: WordCloudService;
    public readonly worksheets: WorksheetsService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:3001',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.aiAssistantFiles = new AiAssistantFilesService(this.request);
        this.aiAssistants = new AiAssistantsService(this.request);
        this.aiChatCompletions = new AiChatCompletionsService(this.request);
        this.aiConversations = new AiConversationsService(this.request);
        this.aiDocumentQas = new AiDocumentQasService(this.request);
        this.aiFeedbackResults = new AiFeedbackResultsService(this.request);
        this.aiFeedbacks = new AiFeedbacksService(this.request);
        this.aiImageRecognitions = new AiImageRecognitionsService(this.request);
        this.aiImages = new AiImagesService(this.request);
        this.aiMessageFiles = new AiMessageFilesService(this.request);
        this.aiMessages = new AiMessagesService(this.request);
        this.aiMiniToolRequests = new AiMiniToolRequestsService(this.request);
        this.aiMiniTools = new AiMiniToolsService(this.request);
        this.aiPersonas = new AiPersonasService(this.request);
        this.aiRuns = new AiRunsService(this.request);
        this.aiSpeeches = new AiSpeechesService(this.request);
        this.aiSpeechRecognitions = new AiSpeechRecognitionsService(this.request);
        this.apps = new AppsService(this.request);
        this.audioRecorder = new AudioRecorderService(this.request);
        this.audioRecordings = new AudioRecordingsService(this.request);
        this.bookmarks = new BookmarksService(this.request);
        this.classroomScreen = new ClassroomScreenService(this.request);
        this.cms = new CmsService(this.request);
        this.collections = new CollectionsService(this.request);
        this.discardedItems = new DiscardedItemsService(this.request);
        this.fileSharing = new FileSharingService(this.request);
        this.folders = new FoldersService(this.request);
        this.items = new ItemsService(this.request);
        this.linkShortener = new LinkShortenerService(this.request);
        this.materials = new MaterialsService(this.request);
        this.ownerFiles = new OwnerFilesService(this.request);
        this.pinboard = new PinboardService(this.request);
        this.pupilFeedback = new PupilFeedbackService(this.request);
        this.schoolClasses = new SchoolClassesService(this.request);
        this.screenRecorder = new ScreenRecorderService(this.request);
        this.search = new SearchService(this.request);
        this.sessions = new SessionsService(this.request);
        this.surveys = new SurveysService(this.request);
        this.templates = new TemplatesService(this.request);
        this.textEditorDocuments = new TextEditorDocumentsService(this.request);
        this.userLimits = new UserLimitsService(this.request);
        this.users = new UsersService(this.request);
        this.videoRecorder = new VideoRecorderService(this.request);
        this.videoRecorderFiles = new VideoRecorderFilesService(this.request);
        this.visitors = new VisitorsService(this.request);
        this.website = new WebsiteService(this.request);
        this.wordCloud = new WordCloudService(this.request);
        this.worksheets = new WorksheetsService(this.request);
    }
}

