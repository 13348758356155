export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:locale',
    /* internal name: '/[locale]' */
    /* no component */
    children: [
      {
        path: 'help',
        name: '/[locale]/help',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/[locale]/help.vue'),
        children: [
          {
            path: '',
            name: 'help-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/[locale]/help/index.vue'),
            /* no children */
          },
          {
            path: 'articles',
            /* internal name: '/[locale]/help/articles' */
            /* no component */
            children: [
              {
                path: ':id-:slug',
                /* internal name: '/[locale]/help/articles/[id]-[slug]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'help-article-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/[locale]/help/articles/[id]-[slug]/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'categories',
            /* internal name: '/[locale]/help/categories' */
            /* no component */
            children: [
              {
                path: ':id-:slug',
                /* internal name: '/[locale]/help/categories/[id]-[slug]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'help-category-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/[locale]/help/categories/[id]-[slug]/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'search',
            /* internal name: '/[locale]/help/search' */
            /* no component */
            children: [
              {
                path: ':query',
                /* internal name: '/[locale]/help/search/[query]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'help-search-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/[locale]/help/search/[query]/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/ai',
    /* internal name: '/ai' */
    /* no component */
    children: [
      {
        path: 'assistants',
        /* internal name: '/ai/assistants' */
        /* no component */
        children: [
          {
            path: '',
            name: 'ai-assistant-index-public',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/assistants/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'ai-assistants-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/assistants/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'chats',
        name: 'ai-chat-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats.vue'),
        children: [
          {
            path: ':id',
            name: 'ai-chat-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/[id].vue'),
            children: [
              {
                path: 'system_prompt',
                /* internal name: '/ai/chats/[id]/system_prompt' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'ai-chat-system-prompt',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/[id]/system_prompt/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'assistants',
            /* internal name: '/ai/chats/assistants' */
            /* no component */
            children: [
              {
                path: '',
                name: 'ai-chat-assistants-index',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/assistants/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: 'ai-chat-assistants-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/assistants/[id].vue'),
                children: [
                  {
                    path: 'settings',
                    name: 'ai-chat-assistants-settings',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/assistants/[id]/settings.vue'),
                    /* no children */
                  },
                  {
                    path: 'share',
                    name: 'ai-chat-assistants-share',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/assistants/[id]/share.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'my',
                /* internal name: '/ai/chats/assistants/my' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'ai-chat-assistants-my',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats/assistants/my/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
        meta: {
          "requiresAuth": true,
          "namespace": "ai_chat"
        }
      },
      {
        path: 'chats/info',
        name: 'ai-chat-info',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats.info.vue'),
        /* no children */
        meta: {
          "namespace": "ai_chat"
        }
      },
      {
        path: 'chats/public_assistants',
        /* internal name: '/ai/chats.public_assistants' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/ai/chats.public_assistants/[id]',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats.public_assistants/[id].vue'),
            children: [
              {
                path: '',
                name: 'ai-assistants-show-public',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats.public_assistants/[id]/index.vue'),
                /* no children */
              },
              {
                path: 'chat',
                /* internal name: '/ai/chats.public_assistants/[id]/chat' */
                /* no component */
                children: [
                  {
                    path: ':conversationId',
                    name: 'ai-assistant-public-chat',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/chats.public_assistants/[id]/chat/[conversationId].vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'document_qas',
        /* internal name: '/ai/document_qas' */
        /* no component */
        children: [
          {
            path: '',
            name: 'document-qa-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/document_qas/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'document-qa-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/document_qas/[id].vue'),
            /* no children */
          },
          {
            path: 'history',
            name: 'ai-document-qa-history',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/document_qas/history.vue'),
            /* no children */
          },
          {
            path: 'info',
            name: '/ai/document_qas/info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/document_qas/info.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'feedbacks',
        /* internal name: '/ai/feedbacks' */
        /* no component */
        children: [
          {
            path: '',
            name: 'feedback-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/feedbacks/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "ai_feedback"
            }
          },
          {
            path: ':feedbackId',
            /* internal name: '/ai/feedbacks/[feedbackId]' */
            /* no component */
            children: [
              {
                path: 'results',
                /* internal name: '/ai/feedbacks/[feedbackId]/results' */
                /* no component */
                children: [
                  {
                    path: ':feedbackResultPage',
                    /* internal name: '/ai/feedbacks/[feedbackId]/results/[feedbackResultPage]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'feedback-result-show',
                        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/feedbacks/[feedbackId]/results/[feedbackResultPage]/index.vue'),
                        /* no children */
                        meta: {
                          "requiresAuth": true,
                          "namespace": "ai_feedback"
                        }
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: ':id',
            /* internal name: '/ai/feedbacks/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'feedback-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/feedbacks/[id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "ai_feedback"
                }
              },
              {
                path: 'results',
                /* internal name: '/ai/feedbacks/[id]/results' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'feedback-result-index',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/feedbacks/[id]/results/index.vue'),
                    /* no children */
                    meta: {
                      "requiresAuth": true,
                      "namespace": "ai_feedback"
                    }
                  }
                ],
              }
            ],
          },
          {
            path: 'info',
            /* internal name: '/ai/feedbacks/info' */
            /* no component */
            children: [
              {
                path: '',
                name: 'feedback-info',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/feedbacks/info/index.vue'),
                /* no children */
                meta: {
                  "namespace": "ai_feedback"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'image_recognitions',
        /* internal name: '/ai/image_recognitions' */
        /* no component */
        children: [
          {
            path: '',
            name: 'image-recognition',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/image_recognitions/index.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: 'image-recognition-history',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/image_recognitions/history.vue'),
            children: [
              {
                path: ':id',
                name: 'image-recognition-history-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/image_recognitions/history/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'info',
            name: '/ai/image_recognitions/info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/image_recognitions/info.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'images',
        /* internal name: '/ai/images' */
        /* no component */
        children: [
          {
            path: '',
            name: 'images-new',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/images/index.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: 'images-history',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/images/history.vue'),
            children: [
              {
                path: ':id',
                name: 'images-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/images/history/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'info',
            name: '/ai/images/info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/images/info.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'mini_tools',
        /* internal name: '/ai/mini_tools' */
        /* no component */
        children: [
          {
            path: '',
            name: 'mini-tools-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/mini_tools/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'mini-tools-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/mini_tools/[id].vue'),
            /* no children */
          },
          {
            path: 'history',
            name: 'mini-tools-history',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/mini_tools/history.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'personas',
        /* internal name: '/ai/personas' */
        /* no component */
        children: [
          {
            path: '',
            name: 'ai-persona-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/personas/index.vue'),
            children: [
              {
                path: 'new',
                name: 'ai-persona-new',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/personas/index/new.vue'),
                /* no children */
              }
            ],
          },
          {
            path: ':id',
            /* internal name: '/ai/personas/[id]' */
            /* no component */
            children: [
              {
                path: 'chat',
                /* internal name: '/ai/personas/[id]/chat' */
                /* no component */
                children: [
                  {
                    path: ':conversationId',
                    name: 'ai_persona-chat-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/personas/[id]/chat/[conversationId].vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'history',
            name: 'ai-persona-history',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/personas/history.vue'),
            /* no children */
          },
          {
            path: 'info',
            name: '/ai/personas/info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/personas/info.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'speech_recognitions',
        /* internal name: '/ai/speech_recognitions' */
        /* no component */
        children: [
          {
            path: '',
            name: 'speech-recognition',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/speech_recognitions/index.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: 'speech-recognition-history',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/speech_recognitions/history.vue'),
            children: [
              {
                path: ':id',
                name: 'speech-recognition-history-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/speech_recognitions/history/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'info',
            name: '/ai/speech_recognitions/info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/ai/speech_recognitions/info.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/audio_recorder',
    /* internal name: '/audio_recorder' */
    /* no component */
    children: [
      {
        path: 'files',
        /* internal name: '/audio_recorder/files' */
        /* no component */
        children: [
          {
            path: '',
            name: 'audio_recorder-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/audio_recorder/files/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "audio_recorder"
            }
          },
          {
            path: ':id',
            name: 'audio_recorder-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/audio_recorder/files/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'audio_recorder-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/audio_recorder/files/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "audio_recorder"
                }
              },
              {
                path: 'share',
                name: 'audio_recorder-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/audio_recorder/files/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "audio_recorder"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "audio_recorder"
            }
          }
        ],
      },
      {
        path: 'info',
        /* internal name: '/audio_recorder/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'audio_recorder-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/audio_recorder/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "audio_recorder"
            }
          }
        ],
      },
      {
        path: 'public_files',
        /* internal name: '/audio_recorder/public_files' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/audio_recorder/public_files/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'audio_recorder-public-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/audio_recorder/public_files/[id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "audio_recorder"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/bookmarks',
    /* internal name: '/bookmarks' */
    /* no component */
    children: [
      {
        path: '',
        name: 'bookmark-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/bookmarks/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/collections',
    /* internal name: '/collections' */
    /* no component */
    children: [
      {
        path: ':id',
        name: 'collections-show',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/collections/[id].vue'),
        children: [
          {
            path: '',
            name: '/collections/[id]/',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/collections/[id]/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'how_to',
        name: 'collections-how-to',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/collections/how_to.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/file_sharing',
    /* internal name: '/file_sharing' */
    /* no component */
    children: [
      {
        path: 'files',
        /* internal name: '/file_sharing/files' */
        /* no component */
        children: [
          {
            path: '',
            name: 'file_sharing-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/file_sharing/files/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "file_sharing"
            }
          },
          {
            path: ':id',
            name: 'file_sharing-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/file_sharing/files/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'file_sharing-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/file_sharing/files/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "file_sharing"
                }
              },
              {
                path: 'share',
                name: 'file_sharing-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/file_sharing/files/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "file_sharing"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "file_sharing"
            }
          }
        ],
      },
      {
        path: 'info',
        name: 'file_sharing-info',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/file_sharing/info.vue'),
        /* no children */
        meta: {
          "namespace": "file_sharing"
        }
      },
      {
        path: 'public_files',
        /* internal name: '/file_sharing/public_files' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'file_sharing-public-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/file_sharing/public_files/[id].vue'),
            /* no children */
            meta: {
              "namespace": "file_sharing"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/folders',
    /* internal name: '/folders' */
    /* no component */
    children: [
      {
        path: '',
        name: 'folders-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/folders/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
      {
        path: ':id',
        /* internal name: '/folders/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: 'folders-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/folders/[id]/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      },
      {
        path: 'discarded',
        /* internal name: '/folders/discarded' */
        /* no component */
        children: [
          {
            path: '',
            name: 'folders-discarded-items',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/folders/discarded/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/gallery',
    name: '/gallery',
    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/gallery.vue'),
    children: [
      {
        path: '',
        name: 'gallery-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/gallery/index.vue'),
        /* no children */
      },
      {
        path: 'community',
        name: 'gallery-community',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/gallery/community.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/gallery/how_to',
    name: 'gallery-how-to',
    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/gallery.how_to.vue'),
    /* no children */
  },
  {
    path: '/go',
    name: 'go-login',
    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/go.vue'),
    /* no children */
  },
  {
    path: '/help',
    /* internal name: '/help' */
    /* no component */
    children: [
      {
        path: '',
        name: 'help-redirect',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/help/index.vue'),
        /* no children */
      },
      {
        path: 'limits',
        /* internal name: '/help/limits' */
        /* no component */
        children: [
          {
            path: '',
            name: 'help-limits-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/help/limits/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/int',
    /* internal name: '/int' */
    /* no component */
    children: [
      {
        path: 'partners',
        name: '/int/partners',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/int/partners.vue'),
        children: [
          {
            path: '',
            name: 'international-partners-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/int/partners/index.vue'),
            /* no children */
            meta: {
              "international": true
            }
          },
          {
            path: ':id-:slug',
            name: 'international-partners-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/int/partners/[id]-[slug].vue'),
            /* no children */
            meta: {
              "international": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/link_shortener',
    /* internal name: '/link_shortener' */
    /* no component */
    children: [
      {
        path: 'info',
        /* internal name: '/link_shortener/info' */
        /* no component */
        children: [
          {
            path: '',
            name: '/link_shortener/info/',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/link_shortener/info/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'links',
        /* internal name: '/link_shortener/links' */
        /* no component */
        children: [
          {
            path: '',
            name: 'link_shortener-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/link_shortener/links/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "link_shortener"
            }
          },
          {
            path: ':id',
            name: 'link_shortener-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/link_shortener/links/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'link_shortener-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/link_shortener/links/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "link_shortener"
                }
              },
              {
                path: 'share',
                name: 'link_shortener-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/link_shortener/links/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "link_shortener"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "link_shortener"
            }
          }
        ],
      },
      {
        path: 'public_links',
        /* internal name: '/link_shortener/public_links' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'link_shortener-public-links',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/link_shortener/public_links/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/login.vue'),
    /* no children */
  },
  {
    path: '/p',
    /* internal name: '/p' */
    /* no component */
    children: [
      {
        path: 'logged_out',
        name: 'pupil-logout-success',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/p/logged_out.vue'),
        /* no children */
      },
      {
        path: 'school_classes',
        /* internal name: '/p/school_classes' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/p/school_classes/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'pupil-school-class-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/p/school_classes/[id]/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "requiresPupil": true
                }
              },
              {
                path: 'projects',
                /* internal name: '/p/school_classes/[id]/projects' */
                /* no component */
                children: [
                  {
                    path: ':projectId',
                    name: 'pupil-school-class-project-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/p/school_classes/[id]/projects/[projectId].vue'),
                    children: [
                      {
                        path: 'share',
                        name: 'pupil-school-class-project-share',
                        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/p/school_classes/[id]/projects/[projectId]/share.vue'),
                        /* no children */
                        meta: {
                          "requiresAuth": true,
                          "requiresPupil": true
                        }
                      }
                    ],
                    meta: {
                      "requiresAuth": true,
                      "requiresPupil": true
                    }
                  }
                ],
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/partners',
    name: '/partners',
    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/partners.vue'),
    children: [
      {
        path: '',
        name: 'partners-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/partners/index.vue'),
        /* no children */
      },
      {
        path: ':id-:slug',
        name: 'partners-show',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/partners/[id]-[slug].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/pinboard',
    /* internal name: '/pinboard' */
    /* no component */
    children: [
      {
        path: 'boards',
        /* internal name: '/pinboard/boards' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pinboard-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "pinboard"
            }
          },
          {
            path: ':id',
            name: 'pinboard-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id].vue'),
            children: [
              {
                path: 'activities',
                /* internal name: '/pinboard/boards/[id]/activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'pinboard-activities',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id]/activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'cards',
                /* internal name: '/pinboard/boards/[id]/cards' */
                /* no component */
                children: [
                  {
                    path: ':cardId',
                    /* internal name: '/pinboard/boards/[id]/cards/[cardId]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'pinboard-card-show',
                        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id]/cards/[cardId]/index.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'members',
                /* internal name: '/pinboard/boards/[id]/members' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'pinboard-members',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id]/members/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'settings',
                name: 'pinboard-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "pinboard"
                }
              },
              {
                path: 'share',
                name: 'pinboard-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "pinboard"
                }
              },
              {
                path: 'theming',
                /* internal name: '/pinboard/boards/[id]/theming' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'pinboard-theming',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/boards/[id]/theming/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "pinboard"
            }
          }
        ],
      },
      {
        path: 'info',
        /* internal name: '/pinboard/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pinboard-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "pinboard"
            }
          }
        ],
      },
      {
        path: 'public_boards',
        /* internal name: '/pinboard/public_boards' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'public-pinboard-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/public_boards/[id].vue'),
            children: [
              {
                path: 'cards',
                /* internal name: '/pinboard/public_boards/[id]/cards' */
                /* no component */
                children: [
                  {
                    path: ':cardId',
                    /* internal name: '/pinboard/public_boards/[id]/cards/[cardId]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'public-pinboard-card-show',
                        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pinboard/public_boards/[id]/cards/[cardId]/index.vue'),
                        /* no children */
                        meta: {
                          "public": true
                        }
                      }
                    ],
                  }
                ],
              }
            ],
            meta: {
              "namespace": "pinboard",
              "public": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/pupil_feedback',
    /* internal name: '/pupil_feedback' */
    /* no component */
    children: [
      {
        path: 'assignments',
        /* internal name: '/pupil_feedback/assignments' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pupil_feedback-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "pupil_feedback"
            }
          },
          {
            path: ':id',
            name: 'pupil_feedback-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'pupil_feedback-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "pupil_feedback"
                }
              },
              {
                path: 'share',
                name: 'pupil_feedback-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "pupil_feedback"
                }
              }
            ],
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: ':id/public_responses',
            name: '/pupil_feedback/assignments/[id].public_responses',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id].public_responses.vue'),
            children: [
              {
                path: ':responseId',
                name: 'pupil_feedback-public_responses-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id].public_responses/[responseId].vue'),
                /* no children */
                meta: {
                  "namespace": "pupil_feedback"
                }
              },
              {
                path: 'new',
                name: 'pupil_feedback-public_responses-new',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id].public_responses/new.vue'),
                /* no children */
                meta: {
                  "namespace": "pupil_feedback"
                }
              }
            ],
          },
          {
            path: ':id/responses',
            /* internal name: '/pupil_feedback/assignments/[id].responses' */
            /* no component */
            children: [
              {
                path: '',
                name: 'pupil_feedback-responses-index',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id].responses/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              },
              {
                path: ':responsePage',
                /* internal name: '/pupil_feedback/assignments/[id].responses/[responsePage]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'pupil_feedback-responses-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/pupil_feedback/assignments/[id].responses/[responsePage]/index.vue'),
                    /* no children */
                    meta: {
                      "requiresAuth": true
                    }
                  }
                ],
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/qrcodes',
    /* internal name: '/qrcodes' */
    /* no component */
    children: [
      {
        path: 'info',
        name: 'qrcodes-info',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/qrcodes/info.vue'),
        /* no children */
        meta: {
          "namespace": "qrcodes"
        }
      },
      {
        path: 'qrcodes',
        name: 'qrcodes-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/qrcodes/qrcodes.vue'),
        /* no children */
        meta: {
          "requiresAuth": true,
          "namespace": "qrcodes"
        }
      }
    ],
  },
  {
    path: '/screen_recorder',
    /* internal name: '/screen_recorder' */
    /* no component */
    children: [
      {
        path: 'files',
        /* internal name: '/screen_recorder/files' */
        /* no component */
        children: [
          {
            path: '',
            name: 'screen_recorder-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/screen_recorder/files/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "screen_recorder"
            }
          },
          {
            path: ':id',
            name: 'screen_recorder-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/screen_recorder/files/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'screen_recorder-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/screen_recorder/files/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "screen_recorder"
                }
              },
              {
                path: 'share',
                name: 'screen_recorder-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/screen_recorder/files/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "screen_recorder"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "screen_recorder"
            }
          }
        ],
      },
      {
        path: 'info',
        /* internal name: '/screen_recorder/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'screen_recorder-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/screen_recorder/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "screen_recorder"
            }
          }
        ],
      },
      {
        path: 'public_files',
        /* internal name: '/screen_recorder/public_files' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/screen_recorder/public_files/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'screen_recorder-public-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/screen_recorder/public_files/[id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "screen_recorder"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/search',
    /* internal name: '/search' */
    /* no component */
    children: [
      {
        path: '',
        name: 'search-index',
        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/search/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/surveys',
    /* internal name: '/surveys' */
    /* no component */
    children: [
      {
        path: 'info',
        /* internal name: '/surveys/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'surveys-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "surveys"
            }
          }
        ],
      },
      {
        path: 'surveys',
        /* internal name: '/surveys/surveys' */
        /* no component */
        children: [
          {
            path: '',
            name: 'surveys-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "surveys"
            }
          },
          {
            path: ':id',
            name: 'surveys-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'surveys-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "surveys"
                }
              },
              {
                path: 'share',
                name: 'surveys-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "surveys"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "surveys"
            }
          }
        ],
      },
      {
        path: 'surveys/:id/public_survey_responses',
        /* internal name: '/surveys/surveys.[id].public_survey_responses' */
        /* no component */
        children: [
          {
            path: ':responseId',
            /* internal name: '/surveys/surveys.[id].public_survey_responses/[responseId]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'survey-responses-public-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys.[id].public_survey_responses/[responseId]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "surveys"
                }
              }
            ],
          },
          {
            path: 'new',
            /* internal name: '/surveys/surveys.[id].public_survey_responses/new' */
            /* no component */
            children: [
              {
                path: '',
                name: 'survey-responses-public-new',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys.[id].public_survey_responses/new/index.vue'),
                /* no children */
                meta: {
                  "namespace": "surveys"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'surveys/:id/public_survey_result',
        /* internal name: '/surveys/surveys.[id].public_survey_result' */
        /* no component */
        children: [
          {
            path: '',
            name: 'survey-responses-public-result-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys.[id].public_survey_result/index.vue'),
            /* no children */
            meta: {
              "namespace": "surveys"
            }
          },
          {
            path: 'responses',
            /* internal name: '/surveys/surveys.[id].public_survey_result/responses' */
            /* no component */
            children: [
              {
                path: ':responsePage',
                /* internal name: '/surveys/surveys.[id].public_survey_result/responses/[responsePage]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'survey-responses-public-result-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys.[id].public_survey_result/responses/[responsePage]/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "surveys"
                    }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'surveys/:id/responses',
        /* internal name: '/surveys/surveys.[id].responses' */
        /* no component */
        children: [
          {
            path: '',
            name: 'surveys-responses-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys.[id].responses/index.vue'),
            /* no children */
            meta: {
              "namespace": "surveys"
            }
          },
          {
            path: ':responsePage',
            /* internal name: '/surveys/surveys.[id].responses/[responsePage]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'surveys-responses-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/surveys/surveys.[id].responses/[responsePage]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "surveys"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/t',
    /* internal name: '/t' */
    /* no component */
    children: [
      {
        path: 'school_classes',
        /* internal name: '/t/school_classes' */
        /* no component */
        children: [
          {
            path: '',
            name: 'teacher-school-class-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "requiresTeacher": true
            }
          },
          {
            path: ':id',
            name: 'teacher-school-class-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id].vue'),
            children: [
              {
                path: 'pupils',
                name: 'teacher-school-class-pupils',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id]/pupils.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "requiresTeacher": true
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "requiresTeacher": true
            }
          },
          {
            path: ':id/projects/:projectId',
            name: 'teacher-school-class-project-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id].projects.[projectId].vue'),
            children: [
              {
                path: 'share',
                name: 'teacher-school-class-project-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id].projects.[projectId]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "requiresTeacher": true
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "requiresTeacher": true
            }
          },
          {
            path: ':id/projects/:projectId/edit',
            name: 'teacher-school-class-project-edit',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id].projects.[projectId].edit.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "requiresTeacher": true
            }
          },
          {
            path: ':id/projects/new',
            name: 'teacher-school-class-project-new',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id].projects.new.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "requiresTeacher": true
            }
          },
          {
            path: ':id/pupils/print',
            name: 'teacher-school-class-pupil-print',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/[id].pupils.print.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "requiresTeacher": true
            }
          },
          {
            path: 'discarded',
            /* internal name: '/t/school_classes/discarded' */
            /* no component */
            children: [
              {
                path: '',
                name: 'teacher-school-class-discarded',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/discarded/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "requiresTeacher": true
                }
              }
            ],
          },
          {
            path: 'info',
            /* internal name: '/t/school_classes/info' */
            /* no component */
            children: [
              {
                path: '',
                name: 'teacher-school-class-info',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/info/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'new',
            /* internal name: '/t/school_classes/new' */
            /* no component */
            children: [
              {
                path: '',
                name: 'teacher-school-class-new',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/t/school_classes/new/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "requiresTeacher": true
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/teaching_board',
    /* internal name: '/teaching_board' */
    /* no component */
    children: [
      {
        path: 'info',
        /* internal name: '/teaching_board/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'classroom_screen-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "classroom_screen"
            }
          }
        ],
      },
      {
        path: 'public_screens',
        /* internal name: '/teaching_board/public_screens' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'classroom_screen-public-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/public_screens/[id].vue'),
            children: [
              {
                path: 'pages',
                /* internal name: '/teaching_board/public_screens/[id]/pages' */
                /* no component */
                children: [
                  {
                    path: ':pageId',
                    name: 'classroom_screen-public-page-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/public_screens/[id]/pages/[pageId].vue'),
                    children: [
                      {
                        path: 'author_info',
                        /* internal name: '/teaching_board/public_screens/[id]/pages/[pageId]/author_info' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'classroom_screen-public-author-info',
                            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/public_screens/[id]/pages/[pageId]/author_info/index.vue'),
                            /* no children */
                          }
                        ],
                      }
                    ],
                  }
                ],
              }
            ],
            meta: {
              "namespace": "classroom_screen"
            }
          }
        ],
      },
      {
        path: 'screens',
        /* internal name: '/teaching_board/screens' */
        /* no component */
        children: [
          {
            path: '',
            name: 'classroom_screen-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "classroom_screen"
            }
          },
          {
            path: ':id',
            name: 'classroom_screen-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/[id].vue'),
            children: [
              {
                path: 'pages',
                /* internal name: '/teaching_board/screens/[id]/pages' */
                /* no component */
                children: [
                  {
                    path: ':pageId',
                    name: 'classroom_screen-page-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/[id]/pages/[pageId].vue'),
                    children: [
                      {
                        path: 'author_info',
                        /* internal name: '/teaching_board/screens/[id]/pages/[pageId]/author_info' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'classroom_screen-author-info',
                            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/[id]/pages/[pageId]/author_info/index.vue'),
                            /* no children */
                          }
                        ],
                      },
                      {
                        path: 'settings',
                        name: 'classroom_screen-settings',
                        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/[id]/pages/[pageId]/settings.vue'),
                        /* no children */
                        meta: {
                          "requiresAuth": true,
                          "namespace": "classroom_screen"
                        }
                      },
                      {
                        path: 'share',
                        name: 'classroom_screen-share',
                        component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/[id]/pages/[pageId]/share.vue'),
                        /* no children */
                        meta: {
                          "requiresAuth": true,
                          "namespace": "classroom_screen"
                        }
                      },
                      {
                        path: 'theme',
                        /* internal name: '/teaching_board/screens/[id]/pages/[pageId]/theme' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: 'classroom_screen-page-theme',
                            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/teaching_board/screens/[id]/pages/[pageId]/theme/index.vue'),
                            /* no children */
                          }
                        ],
                      }
                    ],
                  }
                ],
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "classroom_screen"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/text_editor',
    /* internal name: '/text_editor' */
    /* no component */
    children: [
      {
        path: 'documents',
        /* internal name: '/text_editor/documents' */
        /* no component */
        children: [
          {
            path: '',
            name: 'text_editor-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/documents/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "text_editor"
            }
          },
          {
            path: ':id',
            name: 'text_editor-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/documents/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'text_editor-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/documents/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "text_editor"
                }
              },
              {
                path: 'share',
                name: 'text_editor-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/documents/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "text_editor"
                }
              }
            ],
            meta: {
              "namespace": "text_editor"
            }
          }
        ],
      },
      {
        path: 'info',
        /* internal name: '/text_editor/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'text_editor-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "text_editor"
            }
          }
        ],
      },
      {
        path: 'public_documents',
        /* internal name: '/text_editor/public_documents' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'text_editor-public-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/public_documents/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'text_editor-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/text_editor/public_documents/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "text_editor"
                }
              }
            ],
            meta: {
              "namespace": "text_editor"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/video_recorder',
    /* internal name: '/video_recorder' */
    /* no component */
    children: [
      {
        path: 'files',
        /* internal name: '/video_recorder/files' */
        /* no component */
        children: [
          {
            path: '',
            name: 'video_recorder-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/video_recorder/files/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "video_recorder"
            }
          },
          {
            path: ':id',
            name: 'video_recorder-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/video_recorder/files/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'video_recorder-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/video_recorder/files/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "video_recorder"
                }
              },
              {
                path: 'share',
                name: 'video_recorder-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/video_recorder/files/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "video_recorder"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "video_recorder"
            }
          }
        ],
      },
      {
        path: 'info',
        /* internal name: '/video_recorder/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'video_recorder-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/video_recorder/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "video_recorder"
            }
          }
        ],
      },
      {
        path: 'public_files',
        /* internal name: '/video_recorder/public_files' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/video_recorder/public_files/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'video_recorder-public-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/video_recorder/public_files/[id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "video_recorder"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/website',
    /* internal name: '/website' */
    /* no component */
    children: [
      {
        path: 'info',
        /* internal name: '/website/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'website-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/website/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "website"
            }
          }
        ],
      },
      {
        path: 'pages',
        /* internal name: '/website/pages' */
        /* no component */
        children: [
          {
            path: '',
            name: 'website-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/website/pages/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "website"
            }
          },
          {
            path: ':id',
            name: 'website-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/website/pages/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'website-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/website/pages/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "website"
                }
              },
              {
                path: 'share',
                name: 'website-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/website/pages/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "website"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "website"
            }
          }
        ],
      },
      {
        path: 'public_pages',
        /* internal name: '/website/public_pages' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/website/public_pages/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'website-public-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/website/public_pages/[id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "website"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/word_clouds',
    /* internal name: '/word_clouds' */
    /* no component */
    children: [
      {
        path: 'info',
        /* internal name: '/word_clouds/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'word_clouds-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/word_clouds/info/index.vue'),
            /* no children */
            meta: {
              "namespace": "word_clouds"
            }
          }
        ],
      },
      {
        path: 'public_word_clouds',
        /* internal name: '/word_clouds/public_word_clouds' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/word_clouds/public_word_clouds/[id]' */
            /* no component */
            children: [
              {
                path: '',
                name: 'word_clouds-public-show',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/word_clouds/public_word_clouds/[id]/index.vue'),
                /* no children */
                meta: {
                  "namespace": "word_clouds"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'word_clouds',
        /* internal name: '/word_clouds/word_clouds' */
        /* no component */
        children: [
          {
            path: '',
            name: 'word_clouds-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/word_clouds/word_clouds/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "word_clouds"
            }
          },
          {
            path: ':id',
            name: 'word_clouds-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/word_clouds/word_clouds/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'word_clouds-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/word_clouds/word_clouds/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "word_clouds"
                }
              },
              {
                path: 'share',
                name: 'word_clouds-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/word_clouds/word_clouds/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "word_clouds"
                }
              }
            ],
            meta: {
              "requiresAuth": true,
              "namespace": "word_clouds"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/worksheet',
    /* internal name: '/worksheet' */
    /* no component */
    children: [
      {
        path: 'info',
        /* internal name: '/worksheet/info' */
        /* no component */
        children: [
          {
            path: '',
            name: 'worksheet-info',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/info/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'worksheets',
        /* internal name: '/worksheet/worksheets' */
        /* no component */
        children: [
          {
            path: '',
            name: 'worksheet-index',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/index.vue'),
            /* no children */
            meta: {
              "requiresAuth": true,
              "namespace": "worksheet"
            }
          },
          {
            path: ':id',
            name: 'worksheet-show',
            component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id].vue'),
            children: [
              {
                path: 'settings',
                name: 'worksheet-settings',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id]/settings.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "worksheet"
                }
              },
              {
                path: 'share',
                name: 'worksheet-share',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id]/share.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true,
                  "namespace": "worksheet"
                }
              },
              {
                path: 'theming',
                /* internal name: '/worksheet/worksheets/[id]/theming' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'worksheet-theming',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id]/theming/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
            meta: {
              "requiresAuth": true
            }
          },
          {
            path: ':id/public_worksheet_responses',
            /* internal name: '/worksheet/worksheets/[id].public_worksheet_responses' */
            /* no component */
            children: [
              {
                path: ':responseId',
                /* internal name: '/worksheet/worksheets/[id].public_worksheet_responses/[responseId]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'worksheet-responses-public-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id].public_worksheet_responses/[responseId]/index.vue'),
                    /* no children */
                    meta: {
                      "noVisitor": true
                    }
                  }
                ],
              },
              {
                path: 'new',
                /* internal name: '/worksheet/worksheets/[id].public_worksheet_responses/new' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'worksheet-responses-public-new',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id].public_worksheet_responses/new/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: ':id/responses',
            /* internal name: '/worksheet/worksheets/[id].responses' */
            /* no component */
            children: [
              {
                path: '',
                name: 'worksheet-responses-index',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id].responses/index.vue'),
                /* no children */
              },
              {
                path: ':responsePage',
                /* internal name: '/worksheet/worksheets/[id].responses/[responsePage]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'worksheet-responses-show',
                    component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/[id].responses/[responsePage]/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'new',
            /* internal name: '/worksheet/worksheets/new' */
            /* no component */
            children: [
              {
                path: '',
                name: 'worksheet-new',
                component: () => import('/build/d6334041-30b2-44ad-8366-45f1cc35961d/app/frontend/pages/worksheet/worksheets/new/index.vue'),
                /* no children */
                meta: {
                  "requiresAuth": true
                }
              }
            ],
          }
        ],
      }
    ],
  }
]
